import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Medical Sexual Abuse Attorney | Bartlett & Grippe",
    heroH1: "Connecticut Medical Sexual Abuse",
    heroH2: "When experience, integrity, and results matter.",
    description:
      "If you've been sexually abused by a medical professional, you deserve justice and healing. Bartlett & Grippe can help. Contact us to protect your rights and interests.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Empowering Victims Of Sexual Abuse By Medical Personnel</h2>
            <p className="mb-8">
              Medical professionals or doctors who commit sexual abuse against
              helpless patients should be punished by our criminal justice
              system. Licensing boards can take action as well if a complaint is
              filed. Yet, prison sentences, sex offender registration and the
              loss of professional licenses do little, if anything to promote
              the healing of victims. The attorneys at Bartlett & Grippe focus
              on protecting the rights and looking out for the best interests of
              their clients victimized by a trusted doctor.
            </p>
            <h3>Giving Sexual Abuse Victims A Voice</h3>
            <p className="mb-8">
              Our goal is to transform something that was beyond your control
              into empowerment. At Bartlett & Grippe, attorney Frank Bartlett
              gives victims a voice. Nonconsensual sexual contact by a medical
              professional not only violates the American Medical Association’s
              Principles of Medical Ethics, but it also breaches trust placed in
              doctors, nurses or anesthetists.
            </p>
            <p className="mb-8">
              The basis for civil action is determined by the Sexual Abuse or
              misuse of the relationship between a licensed health care provider
              and a patient. While being proactive in putting our clients in
              control by holding an abuser accountable through a legal claim,
              lawsuits are not always the initial step. During the initial
              confidential consultation, Mr. Bartlett discusses the need for
              treatment and counseling ahead of a complex and often lengthy
              legal process. Your well-being is the most important factor.
            </p>
            <h3>Experience</h3>
            <p className="mb-8">
              Attorney Bartlett has represented many sexual abuse victims over
              his career. He has brought numerous cases to trial and has
              successfully argued several cases before the Connecticut Appellate
              Court and the Connecticut Supreme Court. He served as lead counsel
              in one of the representative cases selected for trial in the
              largest sexual abuse case against a medical professional in
              Connecticut history. The case was against St. Francis Hospital
              whose head of endocrinology, Dr. George Reardon, photographed and
              fondled many of his young patients. In 2007, Reardon’s hidden
              pornographic library of child patients was found in his former
              West Hartford home.
            </p>
            <p className="mb-8">
              The majority of Attorney Bartlett’s sex abuse cases deal with
              adult victims of sexual abuse as children. The abuse often
              happened many years ago and there may be very few records
              associated with the events. Attorney Bartlett and his team of
              experts work together with you to piece together the facts and
              records. If your case goes to trial we will not pass you on to
              another attorney. We have the trial experience to take your case
              to court and up through the appeals process if necessary. We will
              be by your side through the entire process. Continuity is
              critical.
            </p>
            <h3>
              We represent clients with sexual abuse by Boy Scout leaders,
              priests, medical professionals, teachers and others
            </h3>
            <ul className="list-disc list-outside ml-6">
              <li>
                <Link to="/ct-sex-abuse-attorney/child-sexual-abuse-attorney/">
                  Sexual abuse home page
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/sexual-abuse-by-priests/">
                  Priest
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/child-sexual-abuse-attorney/">
                  Family member, foster parent
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/ct-boy-scout-sexual-abuse-lawyer/">
                  Boy Scout leader
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/child-sexual-abuse-attorney/">
                  Teacher
                </Link>
              </li>
            </ul>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
